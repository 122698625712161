@import '~bootstrap/dist/css/bootstrap.css';
@import '~react-rrule-generator/build/styles.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fakeheader div {
  margin-left: 0px;
  padding-left: 0px;
  font-family: 'Roboto', 'sans-serif';
  font-size: 16px;
  font-weight: 500;
  color: rgb(71, 84, 102);
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.nopadding div {
  height: 22px;
  width: 22px;
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg' version='1.0'%3E%3Cg class='layer'%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath d='m197.12,100a97.12,97.12 0 1 1 -194.24,0a97.12,97.12 0 1 1 194.24,0z' fill='%23fff' id='path2382' stroke-width='0'/%3E%3Cg id='layer1'%3E%3Cpath d='m100,0c-55.2,0 -100,44.8 -100,100c0,55.2 44.8,100 100,100s100,-44.8 100,-100s-44.8,-100 -100,-100zm0,12.81c48.13,0 87.19,39.06 87.19,87.19s-39.06,87.19 -87.19,87.19s-87.19,-39.06 -87.19,-87.19s39.06,-87.19 87.19,-87.19zm1.47,21.25c-5.45,0.03 -10.65,0.74 -15.28,2.07c-4.7,1.34 -9.13,3.48 -12.88,6.21c-3.24,2.37 -6.33,5.39 -8.68,8.54c-4.16,5.54 -6.47,11.65 -7.07,18.68c-0.04,0.47 -0.07,0.87 -0.06,0.88c0.02,0.01 21.7,2.69 21.81,2.69c0.05,0 0.11,-0.24 0.28,-0.94c1.94,-8.09 5.49,-13.52 10.97,-16.81c4.32,-2.6 9.81,-3.62 15.78,-2.97c2.74,0.29 5.21,0.96 7.38,2c2.71,1.3 5.18,3.36 6.94,5.81c1.54,2.16 2.46,4.58 2.75,7.31c0.08,0.76 0.05,2.48 -0.03,3.22c-0.23,1.83 -0.7,3.38 -1.5,4.97c-0.81,1.6 -1.48,2.51 -2.76,3.81c-2.03,2.08 -5.18,4.83 -10.78,9.41c-3.6,2.94 -6.04,5.15 -8.12,7.34c-4.94,5.18 -7.19,9.07 -8.56,14.72c-0.91,3.72 -1.26,7.55 -1.16,13.19c0.02,1.4 0.06,2.73 0.06,2.97l0,0.43l21.6,0l0.03,-2.4c0.03,-3.27 0.21,-5.37 0.56,-7.41c0.57,-3.27 1.43,-5 3.94,-7.81c1.6,-1.8 3.7,-3.76 6.93,-6.47c4.77,-3.99 8.11,-6.99 11.26,-10.13c4.91,-4.9 7.46,-8.26 9.28,-12.18c1.43,-3.09 2.22,-6.17 2.46,-9.53c0.06,-0.82 0.07,-3.03 0,-3.97c-0.45,-7.05 -3.1,-13.26 -8.15,-19.03c-0.8,-0.91 -2.78,-2.89 -3.72,-3.72c-4.96,-4.4 -10.69,-7.36 -17.56,-9.1c-4.19,-1.06 -8.23,-1.6 -13.35,-1.75c-0.78,-0.02 -1.59,-0.03 -2.37,-0.03zm-10.91,103.6l0,22l22,0l0,-22l-22,0z' fill='%2394A1B3' id='path2413'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 7px !important;
  margin-left: 3px !important;
}

.centercells td {
  text-align: center;
}

.nomargins {
  margin: 0px;
  padding: 0px;
}
.nomargins div {
  margin: 0px;
  padding: 0px;
}

.nomarginsnoheight {
  height: unset !important;
  margin: 0px;
  padding: 0px;
}

.nomarginsnoheight div {
  margin: 0px;
  padding: 0px;
}

.invalid .webix_inp_static {
    background-color: rgb(255, 219, 219) !important;
}

.webix_cell {
    text-overflow: ellipsis;
}

.questionmark {
    background-image: url("data:image/svg+xml,%3Csvg id='svg2' xmlns='http://www.w3.org/2000/svg' height='200' width='200' version='1.0'%3E%3Cpath id='path2382' d='m165.33 113.44a103.61 103.61 0 1 1 -207.22 0 103.61 103.61 0 1 1 207.22 0z' transform='matrix(.93739 0 0 .93739 42.143 -6.3392)' stroke-width='0' fill='%23fff'/%3E%3Cg id='layer1'%3E%3Cpath id='path2413' d='m100 0c-55.2 0-100 44.8-100 100-5.0495e-15 55.2 44.8 100 100 100s100-44.8 100-100-44.8-100-100-100zm0 12.812c48.13 0 87.19 39.058 87.19 87.188s-39.06 87.19-87.19 87.19-87.188-39.06-87.188-87.19 39.058-87.188 87.188-87.188zm1.47 21.25c-5.45 0.03-10.653 0.737-15.282 2.063-4.699 1.346-9.126 3.484-12.876 6.219-3.238 2.362-6.333 5.391-8.687 8.531-4.159 5.549-6.461 11.651-7.063 18.687-0.04 0.468-0.07 0.868-0.062 0.876 0.016 0.016 21.702 2.687 21.812 2.687 0.053 0 0.113-0.234 0.282-0.937 1.941-8.085 5.486-13.521 10.968-16.813 4.32-2.594 9.808-3.612 15.778-2.969 2.74 0.295 5.21 0.96 7.38 2 2.71 1.301 5.18 3.361 6.94 5.813 1.54 2.156 2.46 4.584 2.75 7.312 0.08 0.759 0.05 2.48-0.03 3.219-0.23 1.826-0.7 3.378-1.5 4.969-0.81 1.597-1.48 2.514-2.76 3.812-2.03 2.077-5.18 4.829-10.78 9.407-3.6 2.944-6.04 5.156-8.12 7.343-4.943 5.179-7.191 9.069-8.564 14.719-0.905 3.72-1.256 7.55-1.156 13.19 0.025 1.4 0.062 2.73 0.062 2.97v0.43h21.598l0.03-2.4c0.03-3.27 0.21-5.37 0.56-7.41 0.57-3.27 1.43-5 3.94-7.81 1.6-1.8 3.7-3.76 6.93-6.47 4.77-3.991 8.11-6.99 11.26-10.125 4.91-4.907 7.46-8.26 9.28-12.187 1.43-3.092 2.22-6.166 2.46-9.532 0.06-0.816 0.07-3.03 0-3.968-0.45-7.043-3.1-13.253-8.15-19.032-0.8-0.909-2.78-2.887-3.72-3.718-4.96-4.394-10.69-7.353-17.56-9.094-4.19-1.062-8.23-1.6-13.35-1.75-0.78-0.023-1.59-0.036-2.37-0.032zm-10.908 103.6v22h21.998v-22h-21.998z'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
}

.dayselector button {
    font-size: 12px !important;
    color: #000 !important;
}

.pill {
    float: left;
    text-align: center;
    width: 25px;
    background-color: #ddd;
    border: none;
    color: black;
    margin: 4px 2px;
    border-radius: 6px;
    padding-top: 5px;
    height: 27px;
    line-height: 18px;
}

.navselect {
  color: rgb(71, 84, 102);
  border: 1px solid rgb(204, 210, 212);
  border-radius: 5px;
}

/*
.navselect:focus {
  z-index: 500;
  height: auto !important;
  position: absolute;
  top: 8px;
  overflow: hidden;
}
*/