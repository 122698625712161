.toolbar {
  border-bottom: 1px solid #000 !important;
}

.hamburger .webix_icon_btn  {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDMwIDMwIj4KPHBhdGggZD0iTSAzIDcgQSAxLjAwMDEgMS4wMDAxIDAgMSAwIDMgOSBMIDI3IDkgQSAxLjAwMDEgMS4wMDAxIDAgMSAwIDI3IDcgTCAzIDcgeiBNIDMgMTQgQSAxLjAwMDEgMS4wMDAxIDAgMSAwIDMgMTYgTCAyNyAxNiBBIDEuMDAwMSAxLjAwMDEgMCAxIDAgMjcgMTQgTCAzIDE0IHogTSAzIDIxIEEgMS4wMDAxIDEuMDAwMSAwIDEgMCAzIDIzIEwgMjcgMjMgQSAxLjAwMDEgMS4wMDAxIDAgMSAwIDI3IDIxIEwgMyAyMSB6Ij48L3BhdGg+Cjwvc3ZnPg==");
  background-size: contain;
  width: 30px;
  height: 30px;
}

.menu {
  height: calc(100vh - 32px) !important;
  top: 32px !important;
}

.loginprompt {
  text-align: center;
}

.header {
  font-size: 1.2em;
  font-weight: bold;
}

.center {
  margin: auto;
}

.multiline{
  line-height: 20px !important;
}

.invalid input {
  background-color: rgb(255, 219, 219) !important;
}

.contents {
  margin-top: 32px;
}

.strikethrough {
  text-decoration: line-through;
}

.dayselector .webix_button:focus {
  background-color: #F4F5F9 !important;
}

.dayselector .webix_button {
  font-weight: normal !important;
}

.dayselector .webix_pressed .webix_button:focus {
  background-color: #daddeb !important;
}

.dayselector .webix_pressed .webix_button {
  font-weight: 500 !important;
}
